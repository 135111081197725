<template>
  <div class="about">
    <h1>This is an about AboutChild1 page</h1>
  </div>
</template>
<script>
export default {
  name: "AboutChild1",
  metaInfo: {
    title: "資料維護",
  },
  data: () => ({
    //
  }),
};
</script>
